import { useReducer } from "react";
import { QueryAllUsers_users_allUsers as User } from "../schema/QueryAllUsers";

export interface State {
  user: User | null;
  groups: string[];
}

export type Action =
  | { type: "SET_USER"; value: User | null }
  | { type: "UPDATE_GROUPS"; value: [string] }
  | { type: "RESET" };

export default function useInviteSolutionMemberReducer(initialState: State) {
  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case "SET_USER":
        return { ...state, user: action.value };
      case "UPDATE_GROUPS":
        return { ...state, groups: action.value };

      case "RESET":
        return { ...initialState };
      default:
        throw new Error();
    }
  }

  return useReducer(reducer, initialState);
}
