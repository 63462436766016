import { useQuery } from "@apollo/client";
import {
  QueryUseCaseVersions,
  queryUseCaseVersions,
  QueryUseCaseVersionsVariables
} from "features/use-case";
import { useEffect, useMemo, useState } from "react";
import semver from "semver";
import { validateUseCaseKey } from "utils/validateUseCaseKey";
import { VersionTreeDataSourceItem } from "../VersionTreeView";

interface Props {
  solutionKey: string;
  useCaseKey: string;
}
function useFetchVersions({ solutionKey, useCaseKey }: Props) {
  const [versions, setVersions] = useState<SemverVersion[] | null>(null);
  const isValidUseCaseKey = validateUseCaseKey(useCaseKey);
  const { data, loading, error } = useQuery<
    QueryUseCaseVersions,
    QueryUseCaseVersionsVariables
  >(queryUseCaseVersions, {
    variables: { solutionKey, useCaseKey },
    skip: !isValidUseCaseKey
  });

  useEffect(() => {
    if (isValidUseCaseKey) {
      const useCases = data?.solutions?.solution?.useCases?.useCases || [];
      const versions = useCases
        .map(({ detail: { version } }) => version)
        .sort(semver.compare);
      setVersions(versions);
    } else {
      setVersions([]);
    }
  }, [data, isValidUseCaseKey]);

  const treeDataSource = useMemo(
    () => buildTreeDataSource(versions),
    [versions]
  );
  return { treeDataSource, loading, error };
}
function buildTreeDataSource(versions: SemverVersion[] | null) {
  return versions?.reduce((acc: VersionTreeDataSourceItem[], version) => {
    const parsedVersion = semver.parse(version);
    const major = parsedVersion?.major;
    const majorVersion = `Version ${major}`;
    if (!acc.some(item => item.key === majorVersion)) {
      acc.push({
        isExpanded: true,
        key: majorVersion,
        majorVersion: undefined
      });
    }
    acc.push({ key: version, majorVersion });
    return acc;
  }, []);
}

export { buildTreeDataSource, useFetchVersions };
