import { gql, useMutation } from "@apollo/client";
import { Button, Divider, Tooltip, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox, ErrorBoxDialog } from "components/error";
import ConfirmGridActionDialog from "components/grid/ConfirmGridActionDialog";
import DataGridView from "components/grid/DataGridView";
import { RestrictedSolutionRealm } from "features/access-management";
import { useSolutionKey, useSolutionName } from "features/solution";
import { IScopedPermission__SolutionScope } from "model/schema/globalTypes";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { DeepOmit } from "utils/typescript";
import { createRoute } from "utils/url";
import DeleteSolutionMemberAction from "./DeleteSolutionMemberAction";
import MemberGroups from "./MemberGroups";
import MemberPermissions from "./MemberPermissions";
import UserName from "./UserName";
import { getAllMemberGroups as getUsedGroups } from "./getAllMemberGroups";
import {
  membersList,
  useQueryGetAvailableGroups,
  useQueryGetMembers
} from "./hooks/membersHooks";
import { Members_solutions_solution_access_users } from "./hooks/schema/Members";
import {
  RemoveSolutionMember,
  RemoveSolutionMemberVariables
} from "./schema/RemoveSolutionMember";
import {
  UpdateMemberGroups,
  UpdateMemberGroupsVariables
} from "./schema/UpdateMemberGroups";

export const UpdateMemberGroupMutation = gql`
  mutation UpdateMemberGroups(
    $solutionKey: Key!
    $groups: [String!]!
    $userName: String!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        access {
          updateUserGroups(groups: $groups, userName: $userName) {
            name
          }
        }
      }
    }
  }
`;
export const removeSolutionMemberMutation = gql`
  mutation RemoveSolutionMember($solutionKey: Key!, $userName: String!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        access {
          removeSolutionMember(userName: $userName) {
            name
          }
        }
      }
    }
  }
`;

export interface IMemberDataProvider {
  data: DeepOmit<Members_solutions_solution_access_users, "__typename">;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    height: "100%"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  left: { display: "flex", alignItems: "center" },
  actions: { display: "flex", alignItems: "center" },
  button: {
    color: "#FFFFFF",
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  },
  membersText: { fontSize: "1.25rem" },
  divider: {
    color: "#E4E6EF",
    height: 20,
    width: 2,
    margin: theme.spacing(0, 2)
  },
  membersCount: {
    color: "#7E8299",
    fontWeight: 500
  },
  skeletonText: {
    width: 200
  },
  skeletonRect: {
    width: "100%",
    height: 500
  },
  grid: { height: 400 },
  cancelButton: {
    textTransform: "capitalize"
  },
  removeButton: {
    textTransform: "capitalize",
    color: "#FFFFFF"
  },
  dialogText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  minWidth: { minWidth: 30 },
  cell: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  overflow: { overflow: "visible !important" },
  centered: {
    ".dx-datagrid-content .dx-datagrid-table .dx-row > td&": {
      verticalAlign: "middle"
    }
  }
}));

export default function Members() {
  const solutionKey = useSolutionKey();
  const { data } = useSolutionName({ solutionKey });
  const solutionName = data?.solutions.solution?.name || solutionKey;
  const classNames = useStyles();
  const history = useHistory();

  const [
    loadMembers,
    { data: members, loading: loadingMembers, error: getMembersError }
  ] = useQueryGetMembers(solutionKey);

  const { data: groups, loading: loadingAvailableGroups } =
    useQueryGetAvailableGroups(solutionKey);

  const [
    updateMemberGroup,
    {
      loading: updateMemberGroupLoading,
      error: updateMemberGroupError,
      reset: resetUpdateMemberGroupError
    }
  ] = useMutation<UpdateMemberGroups, UpdateMemberGroupsVariables>(
    UpdateMemberGroupMutation,
    {
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: membersList, variables: { solutionKey } }]
    }
  );

  const [
    removeSolutionMember,
    {
      error: removeSolutionMemberError,
      reset: resetRemoveSolutionMemberError,
      loading: removeSolutionMemberLoading
    }
  ] = useMutation<RemoveSolutionMember, RemoveSolutionMemberVariables>(
    removeSolutionMemberMutation,
    {
      refetchQueries: [{ query: membersList, variables: { solutionKey } }]
    }
  );

  const [openRemoveUserDialog, setOpenRemoveUserDialog] = React.useState(false);
  const [userName, setUserName] = React.useState("");

  const handleCloseRemoveUserDialog = () => {
    setOpenRemoveUserDialog(false);
  };

  const isLoading =
    loadingMembers ||
    loadingAvailableGroups ||
    updateMemberGroupLoading ||
    removeSolutionMemberLoading;

  const mutationError = updateMemberGroupError || removeSolutionMemberError;
  function notNull<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }
  const allMembers =
    members?.solutions.solution?.access?.users?.filter(notNull) || [];
  const usedGroups = getUsedGroups(allMembers);
  const allGroups =
    groups?.solutions.solution?.access?.availableGroups.map(
      group => group.name
    ) || [];

  const filterDataSource = (data: any) => {
    data.dataSource.postProcess = () => {
      return usedGroups.map((name: any) => ({ text: name, value: name }));
    };
  };

  useEffect(() => {
    loadMembers();
  }, [loadMembers]);
  return (
    <div className={classNames.root}>
      <div className={classNames.header}>
        <div className={classNames.left}>
          <h5
            className={classNames.membersText}
          >{`Members of ${solutionName}`}</h5>
          {allMembers ? (
            <>
              <Divider orientation="vertical" className={classNames.divider} />
              <Typography
                className={classNames.membersCount}
                data-testid="members-count"
              >{`${allMembers?.length} Total`}</Typography>
            </>
          ) : null}
        </div>
        <div className={classNames.actions}>
          <RestrictedSolutionRealm
            requiredPermissions={[
              IScopedPermission__SolutionScope.ReadSolutionMembers,
              IScopedPermission__SolutionScope.InviteSolutionMember
            ]}
            silent
          >
            <Tooltip title={`Invite member to solution ${solutionName}`}>
              <div>
                <Button
                  className={classNames.button}
                  variant="contained"
                  data-testid="add-user-button"
                  onClick={() =>
                    history.push(
                      createRoute(`/solutions/${solutionKey}/members/invite`)
                    )
                  }
                >
                  Invite member
                </Button>
              </div>
            </Tooltip>
          </RestrictedSolutionRealm>
          <Tooltip title="Refresh">
            <div>
              <Button
                variant="contained"
                data-testid="refresh-button"
                className={classNames.button}
                onClick={() => loadMembers()}
              >
                Refresh
              </Button>
            </div>
          </Tooltip>
        </div>
        {openRemoveUserDialog ? (
          <ConfirmGridActionDialog
            message={`You are about to remove ${userName}`}
            onConfirm={() =>
              removeSolutionMember({ variables: { userName, solutionKey } })
            }
            open={openRemoveUserDialog}
            onClose={handleCloseRemoveUserDialog}
          />
        ) : null}
        <ErrorBoxDialog
          apolloError={mutationError}
          onClose={() => {
            handleCloseRemoveUserDialog();
            if (removeSolutionMemberError) resetRemoveSolutionMemberError();
            if (updateMemberGroupError) resetUpdateMemberGroupError();
          }}
        />
      </div>
      <DataGridView<Members_solutions_solution_access_users>
        dataSource={allMembers}
        isLoading={isLoading}
        showFilterRow={false}
        allowSelection={false}
        height={getMembersError ? 35 : "100%"}
        onCellPrepared={e => {
          if (e.column.dataField === "groups") {
            e.cellElement.classList.add(classNames.overflow);
          }
        }}
        columns={[
          {
            colDef: {
              dataField: "name",
              caption: "Name",
              sortOrder: "asc",
              width: "auto"
            },
            render: p => <UserName data={p.data} />
          },
          {
            colDef: {
              dataField: "groups",
              caption: "Groups",
              cssClass: classNames.centered,
              allowSorting: false,
              headerFilter: {
                dataSource: filterDataSource
              },

              calculateFilterExpression: (
                filterValue: any,
                selectedFilterOperation: string | null
              ): [
                (data: Members_solutions_solution_access_users) => boolean,
                string,
                boolean
              ] => {
                if (filterValue) {
                  const selector = (
                    data: Members_solutions_solution_access_users
                  ) => (data.groups || []).includes(filterValue);
                  return [selector, selectedFilterOperation || "=", true];
                }
                // No filter value provided, return a no-op filter
                return [() => true, "=", false];
              }
            },
            render: ({ data }) => {
              return (
                <MemberGroups
                  memberGroups={data.groups?.filter(notNull)}
                  allGroups={allGroups}
                  loadingGroups={updateMemberGroupLoading}
                  onMemberGroupUpdated={(groups: [string]) => {
                    updateMemberGroup({
                      variables: {
                        solutionKey,
                        userName: data.name,
                        groups
                      }
                    });
                  }}
                />
              );
            }
          },
          {
            colDef: {
              caption: "Actions",
              width: 130,
              allowFiltering: false,
              allowSorting: false
            },
            render: ({ data }) => (
              <div className={classNames.cell}>
                <MemberPermissions
                  firstName={data.firstName}
                  lastName={data.lastName}
                  solutionName={solutionName}
                  permissions={data.permissions}
                />
                <DeleteSolutionMemberAction
                  data={data}
                  handleUserDeleteRequest={(userName: string) => {
                    setUserName(userName);
                    setOpenRemoveUserDialog(true);
                  }}
                  solutionName={solutionName}
                />
              </div>
            )
          }
        ]}
      />
      {getMembersError ? <ErrorBox apolloError={getMembersError} /> : null}
    </div>
  );
}
