import { LinearProgress, Typography } from "@mui/material";
import { DropDownBox, TreeView } from "devextreme-react";
import { isEqual } from "lodash";
import { useCallback, useRef } from "react";
import { nameof } from "utils/typescript";
import { useStyles } from "./FiltersComponentsStyle";

export interface VersionTreeDataSourceItem {
  isExpanded?: boolean;
  key: string;
  majorVersion?: string;
}
export interface VersionTreeViewProps {
  versions: SemverVersion[] | null;
  treeDataSource: VersionTreeDataSourceItem[];
  setVersions: (versions: SemverVersion[] | null) => void;
  loadingVersions: boolean;
}

type TreeViewInstance = {
  unselectAll: () => void;
  selectItem: (key: string) => void;
};

const VersionTreeView: React.FC<VersionTreeViewProps> = ({
  versions,
  treeDataSource,
  setVersions,
  loadingVersions
}) => {
  const classNames = useStyles();
  const versionTreeViewRef = useRef<TreeView<string> | null>(null);

  const getNonVirtualNodeKeys = useCallback(
    (keys: SemverVersion[] | null) => {
      if (!treeDataSource) return;
      if (keys?.length === 0) return null;
      return keys?.filter((key: string) => {
        const node = treeDataSource.find(item => item.key === key);
        const isVirtual = node?.majorVersion == null;
        return !isVirtual;
      });
    },
    [treeDataSource]
  );

  const handleItemSelectionChange = useCallback(
    (e: {
      component: { getSelectedNodeKeys: () => SemverVersion[] | null };
    }) => {
      const { component } = e;
      const selectedKeys = component.getSelectedNodeKeys();
      const filteredKeys = getNonVirtualNodeKeys(selectedKeys);
      if (filteredKeys !== undefined) {
        setVersions(filteredKeys);
      }
    },
    [setVersions, getNonVirtualNodeKeys]
  );

  const syncTreeViewSelection = useCallback(
    ({ value }) => {
      const treeViewInstance = (
        versionTreeViewRef.current as { instance: TreeViewInstance } | null
      )?.instance;

      if (treeViewInstance) {
        treeViewInstance.unselectAll();
        if (value === null) {
          setVersions([]);
        } else {
          const values = value || versions;
          values?.forEach((value: string) => {
            treeViewInstance.selectItem(value);
          });
        }
      }

      setVersions(getNonVirtualNodeKeys(value) || []);
    },
    [versions, getNonVirtualNodeKeys, setVersions]
  );
  const treeViewRender = useCallback(
    () => (
      <TreeView
        dataSource={treeDataSource}
        ref={versionTreeViewRef}
        dataStructure="plain"
        keyExpr={nameof<VersionTreeDataSourceItem>("key")}
        parentIdExpr={nameof<VersionTreeDataSourceItem>("majorVersion")}
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        selectNodesRecursive={true}
        displayExpr={nameof<VersionTreeDataSourceItem>("key")}
        expandedExpr={nameof<VersionTreeDataSourceItem>("isExpanded")}
        selectByClick={false}
        onItemSelectionChanged={handleItemSelectionChange}
        onContentReady={e => {
          // Only call syncTreeViewSelection if the versions has changed
          const treeViewInstance = (versionTreeViewRef.current as any)
            ?.instance;
          if (!isEqual(treeViewInstance?.getSelectedNodeKeys(), versions)) {
            syncTreeViewSelection({ value: versions });
          }
        }}
      />
    ),
    [treeDataSource, versions, handleItemSelectionChange, syncTreeViewSelection]
  );

  return (
    <div className={classNames.selectBoxWrapper}>
      <div className={classNames.indicatorWrapper}>
        {" "}
        {loadingVersions && <LinearProgress />}
      </div>
      <Typography className={classNames.label}>Versions</Typography>
      <DropDownBox
        valueExpr={nameof("key")}
        displayExpr={nameof("key")}
        showClearButton={true}
        value={versions}
        contentRender={treeViewRender}
        dataSource={treeDataSource}
        className={classNames.selectBox}
        dropDownOptions={{
          wrapperAttr: {
            class: classNames.treeView
          }
        }}
        elementAttr={{ "data-testid": "version-tree-view" }}
        onValueChanged={({ value = null }) => {
          if (!isEqual(value, versions)) {
            syncTreeViewSelection({ value });
          }
        }}
        placeholder="Select versions"
      />
    </div>
  );
};

export default VersionTreeView;
