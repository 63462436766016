import { gql, useQuery } from "@apollo/client";
import { validateUseCaseKey } from "utils/validateUseCaseKey";
import { TablesList, TablesListVariables } from "./schema/TablesList";

export const queryTablesList = gql`
  query TablesList($solutionKey: Key!, $useCaseKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCases(
            version: "*"
            useCaseKey: $useCaseKey
            latestVersionOnly: false
          ) {
            schema {
              input {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default function useQueryTablesList(variables: TablesListVariables) {
  const isValidUseCaseKey = validateUseCaseKey(variables.useCaseKey);
  return useQuery<TablesList, TablesListVariables>(queryTablesList, {
    variables,
    skip: !isValidUseCaseKey
  });
}
