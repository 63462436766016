import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    flex: { display: "flex", flexDirection: "column" },
    root: {
      display: "flex"
    },
    selectBoxWrapper: {
      display: "flex",
      flexDirection: "column",
      width: 300,
      marginRight: 16
    },
    label: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "16px",
      fontFamily: "Segoe UI",
      color: "#1E1E1E",
      height: 16
    },
    selectBox: {
      height: 32,
      width: 300,
      backgroundColor: "#ffffff !important",
      border: "solid 1px #828282",
      borderRadius: 2,
      padding: 4,
      gap: 3,
      "&.dx-texteditor.dx-editor-filled::after": {
        borderBottom: "none !important"
      },
      "&.dx-texteditor-container,.dx-texteditor-input-container": {
        alignItems: "center"
      },
      "&.dx-texteditor.dx-editor-filled .dx-texteditor-input": {
        color: "#1E1E1E !important",
        fontSize: 12,
        fontWeight: 600,
        lineHeight: "16px",
        fontFamily: "Segoe UI",
        padding: 0
      },
      "&.dx-texteditor.dx-editor-filled .dx-placeholder::before": {
        content: "none"
      },
      "&.dx-editor-filled .dx-texteditor-buttons-container:last-child > .dx-dropdowneditor-button":
        {
          width: 16,
          minWidth: 16,
          marginRight: 0
        },
      "&.dx-editor-filled .dx-texteditor-buttons-container:last-child > .dx-dropdowneditor-button .dx-dropdowneditor-icon":
        {
          color: "#464646",
          width: 16
        },
      "&.dx-texteditor.dx-state-hover": {
        border: "solid 2px #828282"
      },
      "&.dx-texteditor.dx-state-focused": {
        border: "solid 2px #0F789B"
      },
      "&.dx-texteditor.dx-state-focused::before": {
        borderBottom: "none"
      }
    },
    dropdownlist: {
      "&.dx-dropdownlist-popup-wrapper.dx-popup-wrapper .dx-overlay-content": {
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.3)",
        border: "1px solid rgba(0, 0, 0, 0.15)",
        borderRadius: 3,
        marginTop: 1
      },
      "&.dx-dropdownlist-popup-wrapper.dx-popup-wrapper .dx-overlay-content .dx-popup-content":
        { height: "auto !important" },
      "&.dx-dropdowneditor-overlay .dx-list-item-content": {
        color: "#1E1E1E !important",
        fontSize: 12,
        fontWeight: 600,
        lineHeight: "16px",
        fontFamily: "Segoe UI"
      },
      "&.dx-dropdowneditor-overlay .dx-list-item.dx-state-hover,.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected.dx-state-hover":
        {
          backgroundColor: "#CDE6EB"
        },
      "&.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected":
        {
          backgroundColor: "#f0f0f0"
        },
      "&.dx-dropdownlist-popup-wrapper .dx-list-item:first-of-type": {
        marginTop: 0
      },
      "&.dx-dropdownlist-popup-wrapper .dx-list-item:last-of-type": {
        marginBottom: 0
      }
    },
    treeView: {
      "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content": {
        boxShadow: "none",
        border: "1px solid #dcdcdc",
        borderRadius: 0
      },
      "&.dx-dropdowneditor-overlay .dx-popup-content": {
        padding: "4px 8px 8px 8px"
      },
      "&.dx-dropdowneditor-overlay .dx-checkbox": {
        top: 0,
        left: 18,
        padding: 3,
        gab: 10
      },
      "&.dx-dropdowneditor-overlay .dx-checkbox-icon": {
        border: "1px solid #464646",
        borderRadius: 3
      },
      "&.dx-dropdowneditor-overlay .dx-checkbox-checked .dx-checkbox-icon,.dx-checkbox-indeterminate .dx-checkbox-icon":
        {
          backgroundColor: "#0F789B",
          border: "none"
        },
      "&.dx-dropdowneditor-overlay .dx-treeview-item-with-checkbox .dx-treeview-item":
        {
          color: "#1E1E1E",
          fontSize: 12,
          fontWeight: 600,
          lineHeight: "16px",
          fontFamily: "Segoe UI",
          padding: "2px 8px",
          height: 24,
          minHeight: 24,
          display: "flex",
          alignItems: "center",
          marginLeft: 15
        },
      "&.dx-dropdowneditor-overlay .dx-treeview-toggle-item-visibility": {
        color: "#464646",
        top: 5,
        width: 16,
        height: 16,
        left: 8,
        marginRight: 8
      },
      "&.dx-dropdowneditor-overlay .dx-treeview-toggle-item-visibility-opened":
        { left: 0 },
      "&.dx-dropdowneditor-overlay .dx-treeview-toggle-item-visibility::before":
        {
          top: 20,
          content: `""`,
          display: "inline-block",
          width: 0,
          height: 0,
          borderBottom: "6px solid transparent",
          borderLeft: "6px solid  #464646",
          borderTop: "6px solid transparent",
          transform: " translateY(-50%)"
        },
      "&.dx-dropdowneditor-overlay .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before":
        {
          content: `""`,
          display: "inline-block",
          width: 0,
          height: 0,
          borderLeft: "6px solid transparent",
          borderRight: "6px solid transparent",
          borderTop: "6px solid #464646",
          top: 20,
          left: 12,
          transform: "translateY(-50%)"
        },
      "&.dx-dropdowneditor-overlay .dx-treeview-item.dx-state-hover": {
        backgroundColor: "#FFFFFF"
      }
    },
    indicatorWrapper: { height: 3 }
  })
);
