import { RestrictedSolutionRealm } from "features/access-management";
import { UsageMetrics } from "features/billing-and-metering";
import {
  AddProject,
  ConnectProject,
  EditProject,
  ProjectName,
  Projects,
  ProjectWrapper
} from "features/project";
import { AccessControl } from "features/public-api";
import { AddSolution, SolutionName, SolutionView } from "features/solution";
import { InviteMember, Members } from "features/solution-member";
import {
  AllJobsResultsWrapper,
  Calculate,
  CalculationName,
  CalculationResultInputTable,
  CalculationResultOutputTable,
  CreateUseCase,
  EditUseCase,
  InputDataTableSet,
  JobResult,
  PublicApi,
  RedirectToLatestUseCaseVersion,
  Topics,
  UploadedDataName,
  UseCaseNameAndVersion,
  UseCases,
  UseCaseVersionSelection
} from "features/use-case";
import {
  Workspace,
  WorkspaceNavigationName,
  Workspaces
} from "features/workspace";
import { IScopedPermission__SolutionScope } from "model/schema/globalTypes";
import React, { lazy } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { Redirect } from "react-router-dom";
import resolvePathname from "resolve-pathname";
import { createRoute } from "utils/url";
import { LandingPage } from "../features/landing-page";
import {
  CalculationIdParams,
  DataIdParams,
  ProjectIdParams,
  RouteModule,
  SolutionKeyParams,
  TableIdParams,
  UseCaseKeyAndVersionParams,
  UseCaseKeyParams,
  WorkspaceParams
} from "./RoutesTyping";

const UploadData = lazy(
  () => import("features/use-case/topics/collect-data/UploadData")
);

export const Title: React.FC<{ title?: string; children: React.ReactNode }> = ({
  title,
  children
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

function getRelativeRoutes(): RouteModule[] {
  return [
    {
      title: "Cloud Platform",
      path: "/",
      View: () => <LandingPage />,
      Navigation: null,
      tab: 0
    },
    {
      title: "API",
      path: "/api",
      View: () => <Redirect to={createRoute("/api/access-control")} />,
      Navigation: () => <div>API</div>,
      tab: 0
    },
    {
      title: "Access Control",
      path: "/api/access-control",
      View: () => <AccessControl />,
      Navigation: () => <div>Access Control</div>,
      tab: 1
    },
    {
      title: "Workspaces",
      path: "/workspaces",
      View: () => <Workspaces />,
      Navigation: () => <div>Workspaces</div>,
      tab: 0
    },
    {
      title: "Workspace",
      path: "/workspaces/:workspaceId",
      View: () => {
        const { workspaceId } = useParams<WorkspaceParams>();
        return <Workspace workspaceId={workspaceId} />;
      },
      Navigation: () => {
        const { solutionKey, workspaceId } = useParams<
          SolutionKeyParams & WorkspaceParams
        >();
        return (
          <WorkspaceNavigationName
            workspaceId={workspaceId}
            solutionKey={solutionKey}
          />
        );
      },
      tab: 1
    },
    {
      title: "Create Solution",
      path: "/create-solution",
      View: () => <AddSolution />,
      Navigation: () => (
        <div data-testid="create-new-solution-nav">Create new solution</div>
      ),
      tab: 0
    },
    {
      title: "Solution",
      path: "/solutions/:solutionKey",
      View: () => {
        return <SolutionView />;
      },
      Navigation: () => {
        const { solutionKey } = useParams<SolutionKeyParams>();
        return <SolutionName solutionKey={solutionKey} />;
      },
      tab: 0
    },

    {
      title: "Projects",
      path: "/solutions/:solutionKey/projects",
      View: () => {
        return <Projects />;
      },
      Navigation: () => <div>Projects</div>,
      tab: 1
    },
    {
      title: "Create Project",
      path: "/solutions/:solutionKey/projects/create",
      View: () => <AddProject />,
      Navigation: () => (
        <div data-testid="create-new-project-nav">Create new project</div>
      ),
      tab: 2
    },

    {
      title: "Project",
      path: "/solutions/:solutionKey/projects/project/:projectId",
      View: () => {
        const { projectId } = useParams<ProjectIdParams>();
        return <ProjectWrapper projectId={projectId} />;
      },
      Navigation: () => {
        const { solutionKey, projectId } = useParams<
          SolutionKeyParams & ProjectIdParams
        >();
        return <ProjectName projectId={projectId} solutionKey={solutionKey} />;
      },
      tab: 2
    },
    {
      title: "Edit Project",
      path: "/solutions/:solutionKey/projects/project/:projectId/edit",
      View: () => {
        const { projectId } = useParams<ProjectIdParams>();
        return projectId ? <EditProject projectId={projectId} /> : null;
      },
      Navigation: () => <div data-testid="edit-project-nav">Edit project</div>,
      tab: 2
    },
    {
      title: "Connect to Project",
      path: "/solutions/:solutionKey/projects/project/:projectId/connect",
      View: () => {
        const { projectId } = useParams<{ projectId: string }>();
        return projectId ? <ConnectProject projectId={projectId} /> : null;
      },
      Navigation: () => <div data-testid="connect-project-nav">Connect</div>,
      tab: 2
    },
    {
      title: "Members",
      path: "/solutions/:solutionKey/members",
      View: () => {
        return (
          <RestrictedSolutionRealm
            requiredPermissions={[
              IScopedPermission__SolutionScope.ReadSolutionMembers
            ]}
          >
            <Members />
          </RestrictedSolutionRealm>
        );
      },
      Navigation: () => <div>Members</div>,
      tab: 1
    },
    {
      title: "Create Member",
      path: "/solutions/:solutionKey/members/invite",
      View: () => {
        return (
          <RestrictedSolutionRealm
            requiredPermissions={[
              IScopedPermission__SolutionScope.ReadSolutionMembers,
              IScopedPermission__SolutionScope.InviteSolutionMember
            ]}
          >
            <InviteMember />
          </RestrictedSolutionRealm>
        );
      },
      Navigation: () => (
        <div data-testid="create-new-member-nav">Invite member</div>
      ),
      tab: 2
    },
    {
      title: "Web Services",
      path: "/solutions/:solutionKey/use-cases",
      View: () => {
        return <UseCases />;
      },
      Navigation: () => <div>Web services</div>,
      tab: 1
    },
    {
      title: "Create Web service",
      path: "/solutions/:solutionKey/create-use-case",
      View: () => {
        return <CreateUseCase />;
      },
      Navigation: () => (
        <div data-testid="create-new-use-case-nav">Create new web service</div>
      ),
      tab: 1
    },
    {
      title: "Create Web Service Version",
      path: "/solutions/:solutionKey/create-use-case-version/:useCaseKey",
      View: () => {
        const { useCaseKey } = useParams<UseCaseKeyParams>();

        return useCaseKey ? <EditUseCase useCaseKey={useCaseKey} /> : null;
      },
      Navigation: () => (
        <div data-testid="create-new-version-nav">Create new version</div>
      ),
      tab: 1
    },
    {
      title: "",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey`,
      View: () => {
        const { useCaseKey } = useParams<UseCaseKeyParams>();
        const { url } = useRouteMatch();
        return (
          <RedirectToLatestUseCaseVersion url={url} useCaseKey={useCaseKey} />
        );
      },
      Navigation: null,
      tab: 2
    },
    {
      title: "Web Service",
      path: "/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion",
      View: () => {
        const { useCaseKey, useCaseVersion } =
          useParams<UseCaseKeyAndVersionParams>();
        return (
          <Topics useCaseKey={useCaseKey} useCaseVersion={useCaseVersion} />
        );
      },
      Navigation: () => {
        const { url } = useRouteMatch();
        const { push } = useHistory();
        const { solutionKey, useCaseKey, useCaseVersion } = useParams<
          SolutionKeyParams & UseCaseKeyAndVersionParams
        >();

        return (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              height: "100%"
            }}
          >
            <UseCaseNameAndVersion
              solutionKey={solutionKey}
              useCaseKey={useCaseKey}
              useCaseVersion={useCaseVersion}
            />
            <UseCaseVersionSelection
              solutionKey={solutionKey}
              onSelectVersion={version =>
                push(resolvePathname(`./${version}`, url))
              }
              useCaseKey={useCaseKey}
            />
          </div>
        );
      },
      tab: 2
    },
    {
      title: "Web Service Data",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion/data`,
      View: () => {
        const { useCaseKey, useCaseVersion } =
          useParams<UseCaseKeyAndVersionParams>();
        return (
          <Calculate useCaseKey={useCaseKey} useCaseVersion={useCaseVersion} />
        );
      },
      tab: 3
    },
    {
      title: "Web Service Data",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion/data/:dataId`,
      View: () => {
        const { useCaseKey, useCaseVersion } =
          useParams<UseCaseKeyAndVersionParams>();
        return (
          <InputDataTableSet
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
          />
        );
      },
      Navigation: () => {
        const { useCaseKey, useCaseVersion, dataId, solutionKey } = useParams<
          UseCaseKeyAndVersionParams & DataIdParams & SolutionKeyParams
        >();
        return (
          <UploadedDataName
            solutionKey={solutionKey}
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
            dataId={dataId}
          />
        );
      },
      tab: 4
    },
    {
      title: "Upload Web Service Data",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion/upload-data`,
      View: () => {
        const { useCaseKey, useCaseVersion } =
          useParams<UseCaseKeyAndVersionParams>();
        return (
          <UploadData useCaseKey={useCaseKey} useCaseVersion={useCaseVersion} />
        );
      },
      Navigation: () => (
        <div data-testid="upload-new-data-nav">Upload new data</div>
      ),
      tab: 3
    },
    {
      title: "Calculations",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion/calculations`,
      View: () => {
        const { solutionKey, useCaseKey, useCaseVersion } = useParams<
          SolutionKeyParams & UseCaseKeyAndVersionParams
        >();
        const { url } = useRouteMatch();
        const { push } = useHistory();
        const navigateToCalculate = () =>
          push(
            createRoute(
              `/solutions/${solutionKey}/use-cases/${useCaseKey}/${useCaseVersion}/data`
            )
          );
        const navigateToCalculation = (calculationId: string) =>
          push(`${url}/${calculationId}`);
        return (
          <AllJobsResultsWrapper
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
            navigateToCalculate={navigateToCalculate}
            navigateToCalculation={navigateToCalculation}
          />
        );
      },
      tab: 3
    },
    {
      title: "Public-API",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion/public-api`,
      View: () => {
        const { useCaseKey, useCaseVersion } =
          useParams<UseCaseKeyAndVersionParams>();
        return (
          <PublicApi useCaseKey={useCaseKey} useCaseVersion={useCaseVersion} />
        );
      },
      Navigation: () => <div>Public API</div>,
      tab: 3
    },
    {
      title: "Calculation",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion/calculations/:calculationId`,
      View: () => {
        const { useCaseKey, useCaseVersion, calculationId } = useParams<
          UseCaseKeyAndVersionParams & CalculationIdParams
        >();
        return (
          <JobResult
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
            calculationId={calculationId}
          />
        );
      },
      Navigation: () => {
        const { solutionKey, useCaseKey, useCaseVersion, calculationId } =
          useParams<
            SolutionKeyParams & UseCaseKeyAndVersionParams & CalculationIdParams
          >();
        return (
          <CalculationName
            solutionKey={solutionKey}
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
            calculationId={calculationId}
          />
        );
      },
      tab: 4
    },
    {
      title: "Input-Table",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion/calculations/:calculationId/input-data/:tableId`,
      View: () => {
        const { useCaseKey, useCaseVersion, calculationId, tableId } =
          useParams<
            UseCaseKeyAndVersionParams & CalculationIdParams & TableIdParams
          >();
        return (
          <CalculationResultInputTable
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
            calculationId={calculationId}
            tableId={tableId}
          />
        );
      },
      tab: 5
    },
    {
      title: "Output-Table",
      path: `/solutions/:solutionKey/use-cases/:useCaseKey/:useCaseVersion/calculations/:calculationId/output-data/:tableId`,
      View: () => {
        const { useCaseKey, useCaseVersion, calculationId, tableId } =
          useParams<
            UseCaseKeyAndVersionParams & CalculationIdParams & TableIdParams
          >();
        return (
          <CalculationResultOutputTable
            useCaseKey={useCaseKey}
            useCaseVersion={useCaseVersion}
            calculationId={calculationId}
            tableId={tableId}
          />
        );
      },
      tab: 5
    },
    {
      title: "Usage-Metrics",
      path: `/solutions/:solutionKey/usage-metrics`,
      View: () => {
        return (
          <RestrictedSolutionRealm
            requiredPermissions={[
              IScopedPermission__SolutionScope.ReadTransactions
            ]}
          >
            <UsageMetrics />
          </RestrictedSolutionRealm>
        );
      },
      Navigation: () => <div>Usage Metrics</div>,
      tab: 1
    }
  ];
}

/**
 * Returns all routes.
 *
 * Transforms relative to absolute routes which is required for currently hosted dev builds.
 */
export default function getRoutes() {
  return getRelativeRoutes().map(({ path, title, View, Navigation, tab }) => ({
    title,
    path: `${process.env.PUBLIC_URL}${path}`,
    View,
    Navigation,
    tab
  }));
}
