import { LinearProgress } from "@mui/material";

import { TagBox } from "devextreme-react";
import { useState } from "react";
import { useStyles } from "./MemberGroupsStyle";

type MemberGroupProps = {
  memberGroups?: string[];
  allGroups?: string[];
  onMemberGroupUpdated: (group: [string]) => void;
  loadingGroups: boolean;
  label?: string;
  height?: number;
};

const MemberGroups: React.FC<MemberGroupProps> = ({
  memberGroups,
  allGroups = [],
  onMemberGroupUpdated,
  loadingGroups,
  label,
  height
}) => {
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (options: any) => {
    const { value } = options;
    if (options.event) {
      onMemberGroupUpdated(value);
    }
  };

  if (!memberGroups) return null;

  return (
    <div className={classes.cell} data-testid="select-group">
      {loadingGroups ? <LinearProgress data-testid="loading-groups" /> : null}
      <TagBox
        dataSource={allGroups}
        value={memberGroups}
        multiline={true}
        onValueChanged={handleChange}
        placeholder={undefined}
        showDropDownButton
        focusStateEnabled={true}
        onFocusIn={() => setIsFocused(true)}
        onFocusOut={() => setIsFocused(false)}
        width={"100%"}
        label={label}
        labelMode="floating"
        style={{
          height: height ?? "100%",
          border: isFocused
            ? "solid 2px #3AA9AE"
            : "solid 1px rgba(0, 0, 0, 0.23)"
        }}
        noDataText="no groups"
        className={classes.root}
      />
    </div>
  );
};
export default MemberGroups;
