import { gql, useQuery } from "@apollo/client";
import { ApiKeysList, ApiKeysListVariables } from "./schema/ApiKeysList";

export const allApiKeys = gql`
  query ApiKeysList($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          apiKeys {
            name
            id
          }
        }
      }
    }
  }
`;

export default function useQueryApiKeysList(variables: ApiKeysListVariables) {
  return useQuery<ApiKeysList, ApiKeysListVariables>(allApiKeys, {
    variables
  });
}
