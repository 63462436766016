export type Period =
  | "last_week"
  | "last_month"
  | "last_6_months"
  | "last_year"
  | "all_time"
  | "custom";

export function assertNever(x: never): never {
  throw new Error(`Unexpected object: ${x}`);
}

const updateDateRange = (
  period: Period,
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>
) => {
  const now = new Date();
  let startDate: Date | undefined;
  let endDate: Date | undefined = new Date(now);

  switch (period) {
    case "last_week":
      startDate = new Date(now.setDate(now.getDate() - 7));
      break;
    case "last_month":
      startDate = new Date(now.setMonth(now.getMonth() - 1));
      break;
    case "last_6_months":
      startDate = new Date(now.setMonth(now.getMonth() - 6));
      break;
    case "last_year":
      startDate = new Date(now.setFullYear(now.getFullYear() - 1));
      break;
    case "all_time":
      startDate = undefined;
      endDate = undefined;
      break;
    case "custom":
      endDate = undefined;
      break;

    default:
      assertNever(period);
  }

  setStartDate(startDate);
  setEndDate(endDate);
};
export default updateDateRange;
