import { gql, useQuery } from "@apollo/client";
import {
  QueryUseCaseName,
  QueryUseCaseNameVariables
} from "./schema/QueryUseCaseName";

export const queryUseCaseName = gql`
  query QueryUseCaseName(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCases(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            detail {
              name
            }
          }
        }
      }
    }
  }
`;

export default function useQueryUseCaseName(
  variables: QueryUseCaseNameVariables
) {
  return useQuery<QueryUseCaseName, QueryUseCaseNameVariables>(
    queryUseCaseName,
    {
      variables,
      fetchPolicy: "no-cache"
    }
  );
}
