import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    height: "100%",
    display: "flex",
    gap: 16
  },
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    height: "100%",
    overflow: "hidden",
    overflowY: "hidden",
    flex: 1
  },
  flex: {
    display: "flex",
    gap: 16,
    flexWrap: "wrap",
    alignItems: "center"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    margin: 8
  },
  resetButton: {
    border: "solid 1px #005F87",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
    borderRadius: 2,
    color: "#005F87",
    height: 32,
    "&:hover": {
      backgroundColor: "#DAECF0",
      boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.3)",
      borderColor: "#005F87"
    },
    "&:active": {
      backgroundColor: "#CDE6EB",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
      borderColor: "#005F87"
    },
    "&:focus": {
      outline: "solid 2px #0F789B",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
      outlineOffset: 2
    }
  },
  applyButton: {
    marginLeft: 8,
    color: "#ffffff",
    backgroundColor: "#005F87",
    border: "solid 1px #003750",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
    borderRadius: 2,
    height: 32,
    "&:hover": {
      backgroundColor: "#004D73",
      boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.3)"
    },
    "&:active": {
      backgroundColor: "#003750",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)"
    },
    "&:focus": {
      outline: "solid 2px #0F789B",
      backgroundColor: "#005F87",
      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
      outlineOffset: 2
    }
  },
  commandBar: {
    width: 60,
    height: "calc(100% + 48px)",
    backgroundColor: "#F0F0F0",
    marginTop: "-24px",
    marginRight: "-24px",
    borderLeft: "solid 1px rgba(0, 0, 0, 0.15)",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.3)",
    padding: "7px 7px 7px 3px"
  },
  button: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    color: "rgba(70, 70, 70, 1)",
    cursor: "pointer",
    marginBottom: 8,
    borderTop: "solid 2px transparent",
    "&:hover": {
      backgroundColor: "rgba(218, 236, 240, 1)",
      borderTop: "solid 2px rgba(102, 200, 222, 1)"
    },
    "&:focus": {
      outline: "solid 2px rgba(15, 120, 155, 1)",
      backgroundColor: "rgba(205, 230, 235, 1)"
    }
  },
  selectedButton: {
    backgroundColor: "rgba(218, 236, 240, 1)",
    borderTop: "solid 2px rgba(102, 200, 222, 1)"
  },
  icon: {
    height: 24,
    width: 24,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  label: { fontSize: 10, fontWeight: 600, fontFamily: "Segoe UI" },
  disabled: {
    color: "rgba(0, 0, 0, 0.3)",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 8
  },
  disabledColor: {
    color: "rgba(0, 0, 0, 0.3)"
  },
  dateRange: {
    width: 300,
    height: 32,
    backgroundColor: "#ffffff !important",
    border: "solid 1px #828282",
    borderRadius: 2,
    "&.dx-texteditor.dx-editor-filled .dx-texteditor-input": {
      color: "#1E1E1E",
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "16px",
      fontFamily: "Segoe UI",
      paddingLeft: 4,
      paddingRight: 0
    },
    "&.dx-daterangebox .dx-daterangebox-separator": {
      paddingLeft: 0
    },
    "&.dx-texteditor.dx-editor-filled .dx-placeholder::before": {
      content: "none"
    },
    "&.dx-texteditor.dx-state-hover": {
      border: "solid 2px #828282"
    },
    "&.dx-texteditor.dx-state-focused": {
      border: "solid 2px #0F789B"
    },
    "&.dx-daterangebox .dx-texteditor.dx-state-focused::before": {
      borderBottom: "none !important"
    },
    "&.dx-texteditor.dx-editor-filled::after": {
      borderBottom: "none",
      bottom: 0
    },
    "&.dx-daterangebox .dx-icon-to": {
      display: "flex",
      alignItems: "center"
    },
    "&.dx-daterangebox .dx-icon-to::before": {
      content: `"-"`,
      fontSize: 12,
      fontWeight: 400,
      color: "#1E1E1E"
    }
  },
  dateRangeDropDown: {
    "&.dx-daterangebox.dx-dropdowneditor-active .dx-dropdowneditor-icon::before":
      {
        color: "#464646"
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar":
      {
        height: 315,
        width: 285
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-body thead th":
      { color: "rgba(0, 0, 0, 0.54)", fontSize: "90%", fontWeight: "bolder" },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-button-mode-text.dx-button-default":
      {
        fontSize: 12,
        fontWeight: 400,
        color: "#1E1E1E",
        border: "solid 1px #828282"
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard)":
      { border: "none" },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-button.dx-button-has-icon.dx-state-hover":
      { backgroundColor: "#FFFFFF", color: "#dc6914" },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-navigator-next-month":
      { backgroundColor: "#FFFFFF", color: "#dc6914" },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-navigator-previous-view.dx-button .dx-icon,.dx-calendar-navigator-next-view.dx-button .dx-icon":
      {
        color: "#464646",
        height: 16,
        width: 16,
        fontSize: 16
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-cell span":
      {
        height: 39,
        width: 39,
        fontSize: 12
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-cell-range-hover::after":
      {
        border: "none"
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-cell.dx-calendar-other-view":
      {
        color: "rgba(57, 57, 57, 0.3)",
        backgroundColor: "transparent"
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-cell.dx-calendar-other-view.dx-state-hover span":
      {
        backgroundColor: "#e6e6e6",
        borderColor: "#e6e6e6"
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-cell.dx-state-hover span":
      {
        backgroundColor: "#daecf0"
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-cell-in-range::before":
      {
        backgroundColor: "#ffffff",
        border: "none"
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-cell.dx-calendar-selected-date span":
      {
        backgroundColor: "#005f87",
        border: "1px solid #dc6914",
        outlineOffset: "1px"
      },
    "&.dx-dropdowneditor-overlay.dx-popup-wrapper .dx-overlay-content .dx-calendar-cell.dx-calendar-contoured-date span":
      {
        boxShadow: "none"
      }
  },
  errorMessage: {
    backgroundColor: "#FBEEED",
    border: "1px solid #FFADAD",
    padding: "3px 5px",
    color: "#DC0000",
    fontSize: 12,
    fontWeight: 400,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    gap: 5
  }
}));
